exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-private-residences-guest-enquiry-js": () => import("./../../../src/pages/private-residences/guest-enquiry.js" /* webpackChunkName: "component---src-pages-private-residences-guest-enquiry-js" */),
  "component---src-pages-private-villas-guest-enquiry-js": () => import("./../../../src/pages/private-villas/guest-enquiry.js" /* webpackChunkName: "component---src-pages-private-villas-guest-enquiry-js" */),
  "component---src-pages-staff-newsletter-signup-js": () => import("./../../../src/pages/staff-newsletter-signup.js" /* webpackChunkName: "component---src-pages-staff-newsletter-signup-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-enquiry-js": () => import("./../../../src/templates/enquiry.js" /* webpackChunkName: "component---src-templates-enquiry-js" */),
  "component---src-templates-experience-js": () => import("./../../../src/templates/experience.js" /* webpackChunkName: "component---src-templates-experience-js" */),
  "component---src-templates-forms-js": () => import("./../../../src/templates/forms.js" /* webpackChunkName: "component---src-templates-forms-js" */),
  "component---src-templates-foundation-js": () => import("./../../../src/templates/foundation.js" /* webpackChunkName: "component---src-templates-foundation-js" */),
  "component---src-templates-itineraries-js": () => import("./../../../src/templates/itineraries.js" /* webpackChunkName: "component---src-templates-itineraries-js" */),
  "component---src-templates-microsite-js": () => import("./../../../src/templates/microsite.js" /* webpackChunkName: "component---src-templates-microsite-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-private-residence-js": () => import("./../../../src/templates/privateResidence.js" /* webpackChunkName: "component---src-templates-private-residence-js" */),
  "component---src-templates-thankyou-js": () => import("./../../../src/templates/thankyou.js" /* webpackChunkName: "component---src-templates-thankyou-js" */),
  "component---src-templates-thankyourates-js": () => import("./../../../src/templates/thankyourates.js" /* webpackChunkName: "component---src-templates-thankyourates-js" */),
  "component---src-templates-thankyouspa-js": () => import("./../../../src/templates/thankyouspa.js" /* webpackChunkName: "component---src-templates-thankyouspa-js" */)
}

